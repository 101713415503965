// @flow 
import { Divider } from '@mui/material';
import * as React from 'react';

export const Testemonial = (props) => {
    return (
        <div className="flex flex-col  h-full justify-between">
            <div className="flex">
                <img width="21px" height="15px" src={"/images/uptest.svg"} className="rounded-xl self-start" alt={"testmonial coimex"} />
                <p className="px-3">{props.item.text}</p>
                <img width="21px" height="15px" src={"/images/downtest.svg"} className="rounded-xl self-end" alt={"testmonial coimex"} />
            </div>
            <div className={`flex items-start mt-8`}>
                <Divider className="w-6 bg-fontColorB mt-[14px] h-[1px] mr-2" />
                <div className="flex flex-col  ">
                    <h1 className="  font-MontserratRegular text-xbase text-black">{props.item.author}</h1>
                    <h1 className="  font-MontserratRegular text-xbase text-black text-xs ">{props.item.title}</h1>
                </div>
            </div>
        </div>
    );
};